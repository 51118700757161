<template>
  <div :ref="scrollRef || ''">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :sort-by="sortBy"
      :item-key="itemKey"
      :options.sync="newOptions"
      :server-items-length="itemsLength"
      :footer-props="{
        'items-per-page-options': [50, 100, 250],
        'disable-items-per-page': disableItemsPerPage,
        'disable-pagination': disablePagination,
      }"
      :items-per-page="itemsPerPage"
      :item-class="itemRowBackground"
      :page.sync="newPage"
      @page-count="newPageCount = $event"
      show-select
      class="elevation-1 table-cursor"
    >
      <!-- :loading="loading" -->
      <!-- @click:row="viewData" -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="align-center">
            {{ newTitle }}
            <span class="ml-2 text-caption">(Total: {{ itemsLength }})</span>
          </v-toolbar-title>
          <v-divider class="ml-4" inset vertical></v-divider>
          <v-col v-if="showFilter">
            <v-btn color="primary" @click="filterMenu = true">
              <v-icon class="mr-2">mdi-filter</v-icon>
              Filter
            </v-btn>
          </v-col>
          <v-dialog v-model="filterMenu" persistent width="550">
            <v-card>
              <v-card-title> Filters </v-card-title>
              <v-container class="pa-5">
                <v-row>
                  <v-col sm="12" v-if="!hideDateRange">
                    <v-row class="align-center">
                      <span class="mx-3">Date Range</span>
                      <date-range-picker
                        v-model="dateRange"
                        :appendToBody="true"
                        :showDropdowns="true"
                        :max-date="new Date()"
                      >
                      </date-range-picker>
                      <v-btn color="warning" class="ml-2" @click="dateRange = { startDate: null, endDate: null }">
                        Clear
                      </v-btn>
                    </v-row>
                  </v-col>
                  <v-col sm="12">
                    <v-select
                      :items="[
                        { value: 'UTC', text: 'UTC+00:00' },
                        { value: 'America/New_York', text: 'Eastern Standard Time' },
                        { value: 'America/Chicago', text: 'Central Standard Time' },
                        { value: 'America/Denver', text: 'Mountain Standard Time' },
                        { value: 'America/Los_Angeles', text: 'Pacific Standard Time' },
                      ]"
                      label="Select Timezone"
                      v-model="selectedTimezone"
                      hide-details
                      outlined
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col sm="12" v-if="!hideServiceClass">
                    <v-select
                      v-model="selectedClasses"
                      :items="service_classes"
                      label="Filter Service Class"
                      :menu-props="{
                        value: serviceClassMenu,
                        maxHeight: 400,
                      }"
                      hide-details
                      clearable
                      multiple
                      outlined
                      dense
                      @click:clear="selectedClasses = []"
                      @click="serviceClassMenu = true"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggleServiceClass">
                          <v-list-item-action>
                            <v-icon :color="selectedClasses.length > 0 ? 'indigo darken-4' : ''">
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.text }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption">
                          (+{{ selectedClasses.length - 1 }} others)
                        </span>
                      </template>
                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-btn small text color="success" class="ml-2" @click="serviceClassMenu = false">Confirm</v-btn>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-container>

              <v-card-actions class="justify-end">
                <v-btn color="primary" @click="update">Apply</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-col col="12" md="4" v-if="!hideSearch">
            <v-text-field
              v-model="searchTerm"
              append-icon="mdi-magnify"
              label="Search"
              class="mr-5"
              hide-details
              single-line
              clearable
              @keydown.enter="update"
              @click:append="update"
              @click:clear="clear"
            ></v-text-field>
          </v-col>
          <v-btn v-if="showExport" color="primary" dark @click="exportData">
            Export Data
            <v-icon class="ml-2">mdi-export</v-icon>
          </v-btn>
          <v-btn v-if="!hideAddBtn" color="primary" dark class="mb-2" :to="{ name: `${url}-create` }">
            New {{ title }}
          </v-btn>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h6"> Are you sure you want to delete this item? </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="accent" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <div class="text-center pt-2">
          <v-pagination v-model="newPage" :length="newPageCount" :total-visible="9"></v-pagination>
        </div>
      </template>

      <template v-slot:item.shipper_id="{ item }">
        <div v-if="item.suspend" class="text-align: center">
          <v-icon center color="red darken-2" style="color: red"> mdi-alert </v-icon>
          <br />
        </div>
        <div v-if="item.shipper_id" color="primary" @click="copyToClipboard(item.shipper_id)">
          {{ item.shipper_id }}
          <v-icon class="text-lg">mdi-file-outline</v-icon>
        </div>
      </template>

      <template v-slot:item.tracking="{ item }">
        <a color="primary" :href="'/labels/' + item.id" style="text-decoration: none;" target="_blank">{{
          item.tracking
        }}</a>
      </template>

      <template v-slot:item.name_full="{ item }">
        <a color="primary" @click="viewData(item.id)">
          {{ item.name }} | {{ item.email }}
          <template v-if="item.company"> | {{ item.company }}</template>
        </a>
      </template>

      <template v-slot:item.name="{ item }">
        <a color="primary" :href="$route.path + '/' + item.id" style="text-decoration: none;" target="_blank">{{
          item.name
        }}</a>
      </template>

      <template v-slot:item.email="{ item }">
        <div color="primary" @click="copyToClipboard(item.email)">
          {{ item.email }}
          <v-icon class="text-lg">mdi-file-outline</v-icon>
        </div>
      </template>

      <template v-slot:item.user="{ item }">
        <a color="primary" :href="'/accounts/' + item.user_id" style="text-decoration: none;" target="_blank">{{
          item.user
        }}</a>
      </template>

      <template v-slot:item.qualified="{ item }">
        <div class="chart" v-if="item.qualified.q || item.qualified.nq">
          <span
            class="block"
            :title="'Qualified: ' + item.qualified.q"
            :style="{ width: getQualifiedWidth(item.qualified).q }"
          >
            <span class="value">{{ getQualifiedWidth(item.qualified).q }}</span>
          </span>
          <span
            class="block"
            :title="'Not Qualified: ' + item.qualified.nq"
            :style="{ width: getQualifiedWidth(item.qualified).nq }"
          >
            <span class="value">{{ getQualifiedWidth(item.qualified).nq }}</span>
          </span>
        </div>
      </template>

      <template v-slot:item.rep_id="{ item }">
        <a color="primary" @click="copyRepID(item)">{{ item.rep_id }}</a>
      </template>

      <template v-slot:item.last_login="{ item }">
        {{ item.last_login | readableDate(timezone) }}
      </template>

      <template v-slot:item.created_at="{ item }"> {{ item.created_at | readableDate(timezone) }} </template>s

      <template v-slot:item.last_login="{ item }">
        {{ item.last_login | readableDate(timezone) }}
      </template>

      <template v-slot:item.shimpent_date="{ item }">
        {{ item.shimpent_date | readableDate(timezone) }}
      </template>

      <template v-slot:item.email_verified_at="{ item }">
        <v-icon x-small :color="item.email_verified_at ? 'success' : 'error'">mdi-checkbox-blank-circle</v-icon>
      </template>

      <!-- <template v-slot:item.insurance_price="{ item }">
        <v-icon x-small :color="item.insurance_price ? 'success' : 'error'">mdi-checkbox-blank-circle</v-icon>
      </template> -->

      <template v-slot:item.notes="{ item }">
        <v-icon x-small :color="item.notes ? 'success' : 'error'">mdi-checkbox-blank-circle</v-icon>
      </template>

      <!-- <template v-slot:item.printed="{ item }">
        <v-icon x-small :color="item.printed ? 'success' : 'error'">mdi-checkbox-blank-circle</v-icon>
      </template> -->

      <template v-slot:item.supreme="{ item }">
        <v-icon x-small :color="item.supreme ? 'success' : 'error'">mdi-checkbox-blank-circle</v-icon>
      </template>

      <template v-slot:item.usps="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon x-small :color="item.usps && item.usps.length > 0 ? 'success' : 'error'" v-bind="attrs" v-on="on"
              >mdi-checkbox-blank-circle</v-icon
            >
          </template>
          <v-card max-width="500" style="font-size: 14px; padding: 8px">
            <v-list-item v-for="rep in item.usps" :key="rep.id">
              <v-list-item-content class="pa-0">
                {{ rep.name }}
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-menu>
      </template>

      <template v-slot:item.notes="{ item }">
        <v-icon
          x-small
          :color="item.notes || (item.activities && item.activities.length > 0) ? 'success' : 'error'"
          @click="
            item.notes || (item.activities && item.activities.length > 0)
              ? showActivitiesModal(item)
              : hideActivitiesModal()
          "
          v-bind="attrs"
          v-on="on"
        >
          mdi-checkbox-blank-circle
        </v-icon>
      </template>

      <template v-slot:item.integrations="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              x-small
              :color="item.integrations && item.integrations.length > 0 ? 'success' : 'error'"
              v-bind="attrs"
              v-on="on"
              >mdi-checkbox-blank</v-icon
            >
          </template>
          <v-card max-width="300">
            <div class="d-flex align-center flex-wrap" style="gap: 12px; padding: 8px">
              <div v-for="integ in item.integrations" :key="integ.id">
                <img :src="`/integrations/${integ.code}/table-icon.png`" height="35" />
                <suffix v-if="integ.integration_count > 1">{{ integ.integration_count }}</suffix>
              </div>
            </div>
          </v-card>
        </v-menu>
      </template>

      <template v-slot:item.return="{ item }">
        <v-icon x-small :color="item.return ? 'success' : 'error'">mdi-checkbox-blank-circle</v-icon>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click.stop="viewData(item.id)">mdi-eye</v-icon>
        <!-- <v-icon small class="mr-2" @click.stop="editItem(item.id)">mdi-pencil</v-icon> -->
        <!-- <v-icon small @click.stop="deleteItem(item.id)">mdi-delete</v-icon> -->
      </template>
      <!-- <template v-slot:no-data>
        <v-btn color="primary">Reset</v-btn>
      </template> -->
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination v-model="newPage" :length="newPageCount" :total-visible="9" @input="scrollToTop"></v-pagination>
    </div>

    <v-dialog v-model="showActivities" persistent width="550">
      <v-card>
        <v-card-title style="display: flex; justify-content: space-between; align-items: center;">
          <div>{{ this.openedUser.name }} Notes</div>
          <div>
            <button @click="hideActivitiesModal()" style=" ">
              <v-icon class="cursor-pointer">mdi-close</v-icon>
            </button>
          </div>
        </v-card-title>

        <v-container style="font-size: 1rem; padding: 0 12px; max-height: 500px; overflow-y: overlay">
          <v-card>
            <v-list-item v-if="this.openedUser.notes" style="padding: 0 8px;">
              <v-list-item-content class="pa-0">
                {{ this.openedUser.notes }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="activity in this.openedUser.activities" :key="activity.id" style="padding: 0 8px;">
              <v-list-item-content class="pa-0 py-3">
                <div>
                  {{ activity.note }}
                </div>
                <small>
                  {{ activity.admin_name }} - {{ formatDate(activity.created_at) }}
                  <i v-if="activity.updated_at" :title="formatDate(activity.updated_at)"> Edited</i>
                </small>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-container>

        <v-card-actions class="justify-end">
          <a :href="'/accounts/' + this.openedUser.id" style="text-decoration: none;" target="_blank">
            <v-btn color="primary">
              Open Account
            </v-btn>
          </a>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
.daterangepicker {
  font-family: 'Inter', sans-serif;
}

.grey {
  background-color: var(--v-grey-base);
}

.chart {
  overflow: hidden;

  .block {
    display: block;
    height: 15px;
    color: #fff;
    font-size: 0.75em;
    float: left;
    background-color: #70ad47;
    position: relative;
    overflow: hidden;
    opacity: 1;
    cursor: pointer;

    &:nth-of-type(2) {
      background-color: #fec134;
    }
  }

  .value {
    display: flex;
    justify-content: center;
  }
}
</style>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import pluralize from 'pluralize'
import axios from 'axios'

import moment from 'moment-timezone'
export default {
  components: {
    DateRangePicker,
  },

  props: {
    title: { type: String, default: '' },
    headers: {
      type: Array,
      default() {
        return []
      },
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    options: {
      type: Object,
      default() {
        return {}
      },
    },
    page: { type: Number, default: 1 },
    pageCount: { type: Number, default: 0 },
    itemsLength: { type: Number, default: -1 },
    itemsPerPage: { type: Number, default: 250 },
    itemKey: { type: String, default: 'id' },
    sortBy: { type: String, default: '' },
    urlName: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    disableItemsPerPage: { type: Boolean, default: false },
    disablePagination: { type: Boolean, default: false },
    hideSearch: { type: Boolean, default: false },
    hideAddBtn: { type: Boolean, default: false },
    showExport: { type: Boolean, default: false },
    exportUrl: { type: String, default: '' },
    exportName: { type: String, default: '' },
    showFilter: { type: Boolean, default: false },
    hideDateRange: { type: Boolean, default: false },
    hideServiceClass: { type: Boolean, default: false },
    timezone: { type: String, default: null },
    scrollRef: { type: String, default: '' },
  },

  data: () => ({
    showActivities: false,
    openedUser: { activities: [] },
    dateRange: { startDate: null, endDate: null },
    searchTerm: '',
    selected: [],
    selectedClasses: [],
    service_classes: [],
    serviceClassMenu: false,
    filterMenu: false,
    selectedTimezone: null,
    dialog: false,
    dialogDelete: false,
    editedItem: {
      name: '',
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: '',
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  computed: {
    newOptions: {
      get: function() {
        return this.options
      },
      set: function(value) {
        // console.log('new option set');
        this.$emit('update:options', value)
        this.$emit('update', this.searchTerm, this.dateRange, this.selectedClasses)
      },
    },

    newPage: {
      get: function() {
        return this.page
      },
      set: function(value) {
        // console.log('new page set');
        this.$emit('update:page', value)
        this.$emit('update:options', Object.assign({}, this.newOptions, { page: value }))
        // this.$emit('update', this.searchTerm, this.dateRange, this.selectedClasses)
      },
    },

    newPageCount: {
      get: function() {
        return this.pageCount
      },
      set: function(value) {
        // console.log('new page count set');
        this.$emit('update:pageCount', value)
        // this.$emit('update', this.searchTerm, this.dateRange, this.selectedClasses)
      },
    },

    newTitle() {
      return pluralize(this.title)
    },

    url() {
      return this.urlName || this.newTitle.toLowerCase()
    },

    selectedAllClasses() {
      return this.selectedClasses.length === this.service_classes.length
    },

    selectedSomeClasses() {
      return this.selectedClasses.length > 0 && !this.selectedAllClasses
    },

    icon() {
      if (this.selectedAllClasses) return 'mdi-close-box'
      if (this.selectedSomeClasses) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  async mounted() {
    const token = this.$store.state.token
    const url = process.env.VUE_APP_API_URL

    // Service Classes
    let _service_classes = JSON.parse(localStorage.getItem('service_classes'))

    if (!_service_classes) {
      const { data: service_classes } = await axios.get(`${url}/api/service-classes`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      localStorage.setItem('service_classes', JSON.stringify(service_classes))
      _service_classes = service_classes
    }
    this.service_classes.push({ value: 'sbr', text: 'Scan-Based Return' })
    this.service_classes = _service_classes.map(s => {
      return { value: s.id, text: s.name }
    })
    this.selectedClasses = this.service_classes.map(s => s.value)
  },

  methods: {
    showActivitiesModal(user) {
      this.openedUser = user
      this.showActivities = true
    },

    hideActivitiesModal() {
      this.openedUser = { activities: [] }
      this.showActivities = false
    },

    copyToClipboard(text) {
      const textarea = document.createElement('textarea')
      textarea.value = text
      textarea.style.position = 'fixed' // Avoid scrolling to bottom
      document.body.appendChild(textarea)
      textarea.focus()
      textarea.select()
      try {
        document.execCommand('copy')
        this.$emit('copied', text)
        this.$root.snackbar.show({ message: 'Copied!', color: 'success' })
      } catch (err) {
        console.error('Failed to copy: ', err)
      }
      document.body.removeChild(textarea)
    },

    update() {
      this.filterMenu = false
      this.$emit('update', this.searchTerm, this.dateRange, this.selectedClasses)
    },

    toggleServiceClass() {
      this.$nextTick(() => {
        if (this.selectedAllClasses) {
          this.selectedClasses = []
        } else {
          this.selectedClasses = this.service_classes.map(sc => sc.value)
        }
      })
    },

    clear() {
      this.searchTerm = ''
      this.update()
    },

    viewData(id) {
      this.$router.push(`/${this.url}/${id}`)
    },

    editItem(id) {
      this.$router.push(`/${this.url}/${id}/edit`)
    },

    goToUser(id) {
      this.$router.push({ name: 'accounts-show', params: { id: id } })
    },

    copyRepID(item) {
      navigator.clipboard.writeText(`https://app.grandshipper.com/#/auth/register/${item.rep_id}`)
      this.$root.snackbar.show({ message: `${item.rep_id} copied to clipboard`, color: 'success' })
    },

    deleteItem(id) {
      // console.log('deleting...', id);
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.closeDelete()
    },

    close() {
      this.dialog = false
    },

    closeDelete() {
      this.dialogDelete = false
    },

    itemRowBackground(item) {
      if (item.shipping_service_id && [1, 8].includes(item.shipping_service_id)) {
        return 'grey'
      }
    },

    formatDate(date) {
      return moment(date).format('MMMM D, YYYY, h:mm A')
    },

    async exportData() {
      try {
        if (this.$root.progressbar) {
          this.$root.progressbar.show()
        }
        const export_url = this.exportUrl ?? `${this.url}/export`

        if (export_url) {
          const token = this.$store.state.token
          const url = process.env.VUE_APP_API_URL
          const { data } = await axios.get(`${url}/api/${export_url}`, {
            responseType: 'blob',
            headers: { Authorization: `Bearer ${token}` },
          })
          const filename = this.exportName ? `${this.exportName}.csv` : `${new Date().getTime()}-${this.url}.csv`
          this.download(data, 'text/csv', filename)
        }
      } catch (error) {
        console.error('error')
      } finally {
        if (this.$root.progressbar) {
          this.$root.progressbar.hide()
        }
      }
    },

    download(content, mimeType, filename) {
      const a = document.createElement('a')
      const blob = new Blob([content], { type: mimeType })
      const url = URL.createObjectURL(blob)
      a.setAttribute('href', url)
      a.setAttribute('download', filename)
      a.click()
    },

    getQualifiedWidth(qualified) {
      if (qualified) {
        const sum = qualified.q + qualified.nq
        return {
          q: parseFloat((qualified.q / sum) * 100).toFixed(2) + '%',
          nq: parseFloat((qualified.nq / sum) * 100).toFixed(2) + '%',
        }
      }
      return { q: 0, nq: 0 }
    },

    scrollToTop() {
      if (this.scrollRef) {
        const element = this.$refs[this.scrollRef]
        element.scrollIntoView({ behavior: 'smooth' })
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    },
  },
}
</script>
